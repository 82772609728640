<template>
  <div class="g-box g-role-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-form
        :model="ruleForm"
        class="demo-form-inline"
        size="small"
        :rules="rules"
        ref="ruleForm"
        label-width="180px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="m-tt">{{ $t("add.基本信息") }}</h2>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item :label="$t('add.角色名称')" prop="name">
              <el-input
                v-model="ruleForm.name"
                :placeholder="$t('global.请输入')"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :label="$t('add.角色描述')" prop="tips">
              <el-input
                v-model="ruleForm.tips"
                :placeholder="$t('global.请输入')"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="m-tt">{{ $t("add.操作权限") }}</h2>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-tabs v-model="activeName" type="card">
              <el-tab-pane
                :label="item.label"
                :name="item.value"
                v-for="item in filter.authorizationOptions"
                :key="item.value"
              >
              </el-tab-pane>
            </el-tabs>
            <el-tree
              :data="getOption('ZEUS').node"
              show-checkbox
              node-key="value"
              :props="defaultProps"
              default-expand-all
              class="u-tree"
              :placeholder="$t('Role.Permission')"
              :ref="'tree-' + getOption('ZEUS').value"
              @check="treeChange"
              v-show="activeName === getOption('ZEUS').value"
            ></el-tree>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="m-btn-group">
      <el-button
        class="u-btn s-submit"
        size="small"
        type="primary"
        @click="submitForm('ruleForm')"
        v-if="!isModeInfo"
      >
        {{ $t("global.submit") }}
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-if="isModeInfo"
        @click="$router.go(-1)"
      >
        {{ $t("global.backText") }}
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-else
        @click="returnPreviousPage"
      >
        {{ $t("global.backText") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from './../../../api'
// import getNavData from './../../../components/nav/nav-data'

export default Vue.extend({
  name: 'role-add',
  components: {
  },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      ruleForm: {
        name: '',
        tips: '',
        authorization: [],
        dataPermission: []
      },
      role: VueCookie.get('role'),
      activeName: 'ZEUS',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      filter: {
        authorizationOptions: [{
          label: this.$t('Role.PermissionTab'),
          value: 'ZEUS',
          node: []
        }],
        dataPermissionOptions: []
      },
      loading: true,
      closedSelectID: [],
      rules: {
        name: [
          { required: true, message: this.$t('Role.roleNameMsg'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        tips: [
          { required: true, message: this.$t('Role.roleDescMsg'), trigger: 'blur' },
          { max: 30, message: this.$t('Role.maxLength'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        dataPermission: [
          { required: false, message: this.$t('Role.dataPermission'), trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    isModeAdd () {
      return this.$route.name === 'role-add'
    },
    isModeEdit () {
      return this.$route.name === 'role-edit'
    },
    isModeInfo () {
      return this.$route.name === 'role-info'
    },
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getOption (type) {
      return this.filter.authorizationOptions.find(item => item.value === type) || {}
    },
    init () {
      // api.auth.getAuthAllPark().then(res => {
      //   this.filter.dataPermissionOptions = res.data.map(item => {
      //     return {
      //       label: item.parkName,
      //       value: item.parkId
      //     }
      //   })
      // })
      api.config.postManagerMenu({
        type: 1
        // roleId: '2'
      }).then(res => {
        this.filter.authorizationOptions = this.filter.authorizationOptions.map(item => {
          // let group = (res.data.find(data => data.code === item.value) || {}).node
          // if (item.value === 'ZEUS') {
          //   group = (res.data.filter(data => data.code === item.value) || [])
          // }
          const group = (res.data.filter(data => data.code === item.value) || [])
          return {
            ...item,
            node: this.getTreeData(group)
          }
        })
        // this.filter.authorizationOptions = this.filter.authorizationOptions.filter(item => VueCookie.get('scope').split(',').includes(item.value))
        console.log('this.filter.authorizationOptions', this.filter.authorizationOptions)

        if (this.isModeEdit || this.isModeInfo) {
          this.getInfo()
        }
      })
      this.activeName = (this.filter.authorizationOptions[0] || {}).value || ''
      if (this.isModeAdd) {
        this.loading = false
      }
    },
    getTreeData (list = []) {
      return list.map(item => {
        return {
          label: item.title,
          value: item.id,
          name: item.name,
          disabled: this.isModeInfo,
          children: this.getTreeData(item.node)
        }
      })
    },
    forTreeData (list = [], selectId) {
      return list.map(item => {
        if (!selectId.includes(item.value)) {
          this.closedSelectID.push(item.value)
        }
        return {
          label: item.title,
          value: item.id,
          name: item.name,
          disabled: this.isModeInfo,
          children: this.forTreeData(item.children, selectId)
        }
      })
    },
    treeChange () {
      const authorization = []
      this.filter.authorizationOptions.forEach(item => {
        this.$refs['tree-' + item.value].getCheckedNodes().forEach(node => {
          authorization.push(node.value)
        })
      })
      this.filter.authorizationOptions.forEach(item => {
        this.$refs['tree-' + item.value].getHalfCheckedNodes().forEach(node => {
          authorization.push(node.value)
        })
      })
      this.ruleForm.authorization = authorization
    },
    getInfo () {
      api.auth.getAuthRoleFindRole({
        query: {
          id: this.$route.params.id
        }
      }).then(res => {
        this.ruleForm.tips = res.data.roleDesc
        this.ruleForm.name = res.data.roleName
        this.ruleForm.authorization = res.data.menuIds
        // this.ruleForm.dataPermission = (res.data.parkIds || '').split(',').filter(item => item).map(item => Number(item))
        this.filter.authorizationOptions.forEach(item => {
          this.closedSelectID = []
          this.forTreeData(item.node, this.ruleForm.authorization)
          this.$refs['tree-' + item.value].setCheckedKeys(this.ruleForm.authorization || [])
          this.closedSelectID.forEach(id => {
            this.$refs['tree-' + item.value].setChecked(id, false)
          })
        })
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isModeAdd) {
            api.auth.postAuthRoleAddRole({
              body: {
                roleName: this.ruleForm.name,
                roleDesc: this.ruleForm.tips,
                menuIds: this.ruleForm.authorization
              }
            }).then(() => {

              this.$router.push('/authority-management/role/role-list')
            })
          }
          if (this.isModeEdit) {
            api.auth.postAuthRoleUpdateRole({
              body: {
                id: this.$route.params.id || '',
                roleName: this.ruleForm.name,
                roleDesc: this.ruleForm.tips,
                // parkIds: this.ruleForm.dataPermission.join(',')
                menuIds: this.ruleForm.authorization
              }
            }).then(() => {

              this.$router.push('/authority-management/role/role-list')
            })
          }
        } else {
          return false
        }
      })
    },
    crop (res) {
      this.ruleForm.img = res.data
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../../assets/css/global-variable.scss";
.g-role-add-box {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
.g-role-add-box {
  .el-tree-node__content > label.el-checkbox {
    z-index: 0;
  }
}
</style>
